<template>
	<div class="relative c-hero-item">
		<a
			href="/"
			aria-label="Link til forsiden"
			:class="[
				'>=768:block text-action block absolute left-1/2 z-20 >=768:-top-190',
				pagetype == 'FrontPage' ? 'block w-215 top-0 ' : 'hidden w-90',
			]"
		>
			<SvgLogoEn
				v-if="culture === 'EN'"
				class="w-full h-170 >=768:h-full"
			/>
			<SvgLogo v-else class="w-full h-170 >=768:h-full" />
		</a>
		<h1 v-if="title || byline">
			<span
				v-if="byline"
				class="
					uppercase
					inline-block
					text-center text-action text-20
					w-full
					mb-7
					>=768:mb-15
				"
				v-html="byline"
			></span>
			<span
				v-if="title"
				class="
					block
					uppercase
					text-center text-48 text-blue
					mb-25
					>=768:mb-40 >=768:text-64
				"
				v-html="title"
			></span>
		</h1>
		<Media
			:data="data"
			:pagetype="pagetype"
			:autoplay="autoplay"
			:lazyload="false"
		/>
	</div>
</template>

<script>
import Media from '~/components/main/Media';
import SvgLogo from '~/assets/svgs/logo.svg?inline';
import SvgLogoEn from '~/assets/svgs/logo-en.svg?inline';

export default {
	name: 'HeroItem',

	components: {
		SvgLogo,
		SvgLogoEn,
		Media,
	},

	props: {
		data: {
			type: Object,
			required: true,
			default: function () {
				return {};
			},
		},
		title: {
			type: String,
			required: false,
		},
		byline: {
			type: String,
			required: false,
		},
		pagetype: {
			type: String,
			required: false,
		},
		autoplay: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	data() {
		const id = this.$store.state?.site?.id;
		const cultures = { 1231: 'DA', 2009: 'EN' };

		return {
			culture: cultures[id] || 'DA',
		};
	},
};
</script>
<style lang="postcss">
.d-front-page-custom .c-hero-item {
	@apply pt-100;
}

.c-hero-item > a {
	transform: translateX(-50%);
}

.c-hero-item > h1 {
	font-weight: 700;
	line-height: 0.75;
}

.c-hero-item iframe {
	@apply absolute;
	@apply top-0;
	@apply left-0;
	@apply w-full;
	@apply h-full;
}

.c-hero-item > span {
	@apply font-Caveat+Brush;
}

@screen >=768 {
	.d-front-page-custom .c-hero-item {
		@apply pt-30;
	}
}
</style>
